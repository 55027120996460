import client from './requestclient';

const opportunities = {
    opportunityCategory: async () => {
        return await client({URL_PATH: `get-opportunity-desk-categories`, method: "GET"})
    },
    opportunityDeskByCategoryId: async (id) => {
        return await client({URL_PATH: `get-opportunity-by-category-id/${id}`, method: "GET"})
    },
    opportunitySingleDetails: async (id) => {
        return await client({URL_PATH: `get-opportunity-by-id/${id}`, method: "GET"})
    },
}

export default opportunities;